import { ApolloProvider } from '@apollo/client';
import { SessionProvider, signOut, useSession } from 'next-auth/react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ErrorBoundary from 'components/ErrorBoundary';
import TwoFactorAuthProvider from 'components/two-factor/TwoFactorAuthProvider';
import VGSProvider from 'components/VGSProvider';
import DatadogRumInit from 'lib/client/datadogRum';
import FullStory from 'lib/client/FullStory';
import { LocaleNS } from 'lib/client/locale-ns';
import RouteListener from 'lib/client/RouteListener';
import ModalProvider from 'providers/ModalProvider';
import { GlobalStyles } from 'styles/globals';
import { NextComponentType } from 'next/types';
import { StatsigProvider } from 'providers/StatsigProvider';
import { useEffect } from 'react';
import { signOutMessage } from 'lib/client/services/postMessageService';
import { TestIdBoundaryProvider } from '@honeybook/hbui';
import SegmentProvider from 'providers/SegmentProvider';
import IntercomProvider from 'providers/IntercomProvider';
import UnitComponentsProvider from 'providers/UnitComponentsProvider';
import useApollo from 'apollo-client';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { chartBase } from 'components/common/charts/chartBase';
import { ToastProvider } from 'providers/ToastProvider';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, PointElement, LineElement, annotationPlugin, ArcElement);
chartBase(ChartJS);

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    const { t } = useTranslation(['common']);
    const { pathname } = useRouter();
    if (pathname === '/auth/token' || pathname === '/terms/[doc_name]' || pathname === '/403') {
        return (
            <>
                <ErrorBoundary>
                    <TestIdBoundaryProvider prefix={'finance'}>
                        <RouteListener />
                        <DatadogRumInit />
                        <Component {...pageProps} />
                    </TestIdBoundaryProvider>
                </ErrorBoundary>
            </>
        );
    }

    return (
        <>
            <Head>
                <title>{t('top-bar-title', { ns: LocaleNS.common })}</title>
            </Head>
            <ErrorBoundary>
                <ToastProvider>
                    <VGSProvider />
                    <UnitComponentsProvider />
                    <GlobalStyles />
                    <RouteListener />
                    <DatadogRumInit />
                    <SessionProvider session={session} refetchInterval={30 * 60} refetchOnWindowFocus={true}>
                        <StatsigProvider>
                            <IntercomProvider />
                            <GatedApp Component={Component} pageProps={pageProps} />
                        </StatsigProvider>
                    </SessionProvider>
                </ToastProvider>
            </ErrorBoundary>
        </>
    );
}

type GatedAppProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: NextComponentType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageProps: any;
};

function GatedApp({ Component, pageProps }: GatedAppProps) {
    const { data: session } = useSession();
    const apolloClient = useApollo();

    useEffect(() => {
        if (session?.error === 'RefreshAccessTokenError') {
            signOutMessage();
            signOut();
        }
    }, [session]);

    return (
        <ErrorBoundary>
            <FullStory />
            <ApolloProvider client={apolloClient}>
                <TestIdBoundaryProvider prefix={'finance'}>
                    <SegmentProvider>
                        <TwoFactorAuthProvider>
                            <ModalProvider>
                                <Component {...pageProps} />
                            </ModalProvider>
                        </TwoFactorAuthProvider>
                    </SegmentProvider>
                </TestIdBoundaryProvider>
            </ApolloProvider>
        </ErrorBoundary>
    );
}

export default appWithTranslation(MyApp);
